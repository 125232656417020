$--font-primary: "Roboto", sans-serif;
$--font-secondary: "Inter", sans-serif;

$--primary-main: #e95d25;
$--primary-light: #dd6b20;

$--font-black: #000000;
$--font-dark: #1a202c;
$--font-mediumdark: #27272a;
$--font-medium: #4a5568;
$--font-light: #767676;
$--font-extralight: #b0b0b0;
$--font-white: #ffffff;
$--font-link: #2c5282;
$--font-error: red;

$--icon-color: #a0aec0;

$--background-extraextralight: #fafafa;
$--background-extralight: #f1f1f1;
$--background-mediumlight: #dddddd;
$--background-light: #767676;
$--background-medium: #4a5568;
$--background-white: #ffffff;

$light: 300;
$normal: 400;
$semibold: 500;
$bold: 700;

$--font-baseline: 1em;

$--text-xs: calc($--font-baseline * 0.75);
$--text-sm: calc($--font-baseline * 0.85);
$--text-md: calc($--font-baseline);
$--text-lg: calc($--font-baseline * 1.15);
$--text-xl: calc($--font-baseline * 1.25);
$--text-xxl: calc($--font-baseline * 1.5);
$--text-x3l: calc($--font-baseline * 1.75);
$--text-x4l: calc($--font-baseline * 2);

$spacer: 1em;

$--baseline: 24px;

/* spacing values */
$--space-xs: calc($--baseline / 4);
$--space-sm: calc($--baseline / 2);
$--space-md: calc($--baseline);
$--space-lg: calc($--baseline * 2);
$--space-xl: calc($--baseline * 3);

//Refined New Variables

/* -------------- Fonts -------------*/

//Family
$fontPrimary: "Roboto", sans-serif;
$fontSecondary: "Inter", sans-serif;

//Weights
$textLight: 300;
$textNormal: 400;
$textSemibold: 500;
$textBold: 600;
$textBolder: 700;
$textBlack: 900;

//sizes
$textxs : 0.75rem;
$textsm : 0.875rem;
$textmd : 1rem;
$textlg : 1.125rem;
$textxl : 1.25rem;
$text2xl : 1.5rem;
$text3xl : 1.875rem;
$text4xl : 2.25rem;
$text5xl : 3rem;
$text6xl : 4rem;

/* -------------- Colors -------------*/

//Theme
$themeMain: #e95d25;
$themeMainlight: #dd6b20;

$white: #fff;
$black: #000;

// gray
$gray100: #f7fafc;
$gray200: #edf2f7;
$gray300: #e2e8f0;
$gray400: #cbd5e0;
$gray500: #a0aec0;
$gray600: #718096;
$gray700: #4a5568;
$gray800: #2d3748;
$gray900: #1a202c;

// orange
$orange100 : #FFFAF0;
$orange200 : #FEEBC8;
$orange300 : #FBD38D;
$orange400 : #F6AD55;
$orange500 : #ED8936;
$orange600 : #DD6B20;
$orange700 : #EB5C24;
$orange800 : #9C4221;
$orange900 : #7B341E;

// blue
$blue100 : #EBF8FF;
$blue200 : #BEE3F8;
$blue300 : #90CDF4;
$blue400 : #63B3ED;
$blue500 : #4299E1;
$blue600 : #3182CE;
$blue700 : #2B6CB0;
$blue800 : #2C5282;
$blue900 : #2A4365;

//red 
$red50 : #ffebee;
$red100 : #ffcdd2;
$red200 : #ef9a9a;
$red300 : #e57373;
$red400 : #ef5350;
$red500 : #f44336;
$red600 : #e53935;
$red700 : #d32f2f;
$red800 : #c62828;
$red900 : #b71c1c;
$redA100 : #ff8a80;
$redA200 : #ff5252;
$redA300 : #ff1744;
$redA400 : #d50000;

/* -------------- Spacers -------------*/

$spacer0 : 0.2rem;
$spacer1 : 0.25rem;
$spacer2 : 0.5rem;
$spacer3 : 0.75rem;
$spacer4 : 1rem;
$spacer5 : 1.25rem; 
$spacer6 : 1.5rem;
$spacer7 : 1.75rem;
$spacer8 : 2rem;
$spacer10 : 2.5rem;
$spacer12 : 3rem;
$spacer16 : 4rem;
$spacer20 : 5rem;
$spacer24 : 6rem;
$spacer32 : 8rem;
$spacer40 : 10rem;
$spacer48 : 12rem;
$spacer56 : 14rem;
$spacer64 : 16rem;

/* -------------- Shadows -------------*/

$boxShadow1: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
$buttonBoxShadow: 0px 1px 2px rgba(0, 0, 0, 0.07);