$spacer: 1em;

.p-0 {
	padding: 0 !important;
}
.p-1 {
	padding: ($spacer * 0.25) !important;
}
.p-2 {
	padding: ($spacer * 0.5) !important;
}
.p-3 {
	padding: ($spacer * 0.75) !important;
}
.p-4 {
	padding: ($spacer * 1) !important;
}
.p-5 {
	padding: ($spacer * 1.5) !important;
}
.pt-0 {
	padding-top: 0 !important;
}
.pt-1 {
	padding-top: ($spacer * 0.25) !important;
}
.pt-2 {
	padding-top: ($spacer * 0.5) !important;
}
.pt-3 {
	padding-top: ($spacer * 0.75) !important;
}
.pt-4 {
	padding-top: ($spacer * 1) !important;
}
.pt-5 {
	padding-top: ($spacer * 1.5) !important;
}
.pb-0 {
	padding-bottom: 0 !important;
}
.pb-1 {
	padding-bottom: ($spacer * 0.25) !important;
}
.pb-2 {
	padding-bottom: ($spacer * 0.5) !important;
}
.pb-3 {
	padding-bottom: ($spacer * 0.75) !important;
}
.pb-4 {
	padding-bottom: ($spacer * 1) !important;
}
.pb-5 {
	padding-bottom: ($spacer * 1.5) !important;
}
.pr-0 {
	padding-right: 0 !important;
}
.pr-1 {
	padding-right: ($spacer * 0.25) !important;
}
.pr-2 {
	padding-right: ($spacer * 0.5) !important;
}
.pr-3 {
	padding-right: ($spacer * 0.75) !important;
}
.pr-4 {
	padding-right: ($spacer * 1) !important;
}
.pr-5 {
	padding-right: ($spacer * 1.5) !important;
}
.pl-0 {
	padding-left: 0 !important;
}
.pl-1 {
	padding-left: ($spacer * 0.25) !important;
}
.pl-2 {
	padding-left: ($spacer * 0.5) !important;
}
.pl-3 {
	padding-left: ($spacer * 0.75) !important;
}
.pl-4 {
	padding-left: ($spacer * 1) !important;
}
.pl-5 {
	padding-left: ($spacer * 1.5) !important;
}
.px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.px-1 {
	padding-left: ($spacer * 0.25) !important;
	padding-right: ($spacer * 0.25) !important;
}
.px-2 {
	padding-left: ($spacer * 0.5) !important;
	padding-right: ($spacer * 0.5) !important;
}
.px-3 {
	padding-left: ($spacer * 0.75) !important;
	padding-right: ($spacer * 0.75) !important;
}
.px-4 {
	padding-left: ($spacer * 1) !important;
	padding-right: ($spacer * 1) !important;
}
.px-5 {
	padding-left: ($spacer * 1.5) !important;
	padding-right: ($spacer * 1.5) !important;
}
.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
.py-1 {
	padding-top: ($spacer * 0.25) !important;
	padding-bottom: ($spacer * 0.25) !important;
}
.py-2 {
	padding-top: ($spacer * 0.5) !important;
	padding-bottom: ($spacer * 0.5) !important;
}
.py-3 {
	padding-top: ($spacer * 0.75) !important;
	padding-bottom: ($spacer * 0.75) !important;
}
.py-4 {
	padding-top: ($spacer * 1) !important;
	padding-bottom: ($spacer * 1) !important;
}
.py-5 {
	padding-top: ($spacer * 1.5) !important;
	padding-bottom: ($spacer * 1.5) !important;
}
