

.text--x4l {
	font-size: $--text-x4l;
}
.text--x3l {
	font-size: $--text-x3l;
}
.text--xxl {
	font-size: $--text-xxl;
}
.text--xl {
	font-size: $--text-xl;
}
.text--lg {
	font-size: $--text-lg;
}
.text--md {
	font-size: $--text-md;
}
.text--sm {
	font-size: $--text-sm;
}
.text--xs {
	font-size: $--text-xs;
}

.text--bold {
	font-weight: $bold;
}
.text--semibold {
	font-weight: $semibold;
}
.text--normal {
	font-weight: $normal;
}
.text--light {
	font-weight: $light;
}

.text-color__black{
	color: $--font-black;
}
.text-color__dark{
	color: $--font-dark;
}
.text-color__mediumdark	{
	color: $--font-mediumdark;
}
.text-color__medium	{
	color: $--font-medium;
}
.text-color__light{
	color: $--font-light;
}
.text-color__extralight{
	color: $--font-extralight;
}
.text-color__white{
	color: $--font-white !important;
}
.text-color__link{
	color: $--font-link;
}
.text-color__error{
	color: $--font-error;
}
.text-color__primary{
	color: $--primary-main;
}
.text-color__primary-light{
	color: $--primary-light;
}