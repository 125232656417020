.navbar__container {
    background-color: #FFFFFF !important;
    font-size: 16px;
    line-height: 150%;
    /* height: 58px; */
    padding-top: 0;
    padding-bottom: 0px;
}

.navbar__container a {
    color: #333333 !important;
    padding: 15px 10px;
}

.navbar__profile > .dropdown-menu.show {
    right: 0;
    left: auto;
}

.nav-link.active {
    background-color: tomato;
}