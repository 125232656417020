.m-0 {
	margin: 0 !important;
}
.m-1 {
	margin: ($spacer * 0.25) !important;
}
.m-2 {
	margin: ($spacer * 0.5) !important;
}
.m-3 {
	margin: ($spacer * 0.75) !important;
}
.m-4 {
	margin: ($spacer * 1) !important;
}
.m-5 {
	margin: ($spacer * 1.5) !important;
}
.m-6 {
	margin: ($spacer * 1.75) !important;
}
.m-7 {
	margin: ($spacer * 2) !important;
}
.m-8 {
	margin: ($spacer * 2.5) !important;
}
.m-9 {
	margin: ($spacer * 2.73) !important;
}
.m-10 {
	margin: ($spacer * 3) !important;
}
.mt-0 {
	margin-top: 0 !important;
}
.mt-1 {
	margin-top: ($spacer * 0.25) !important;
}
.mt-2 {
	margin-top: ($spacer * 0.5) !important;
}
.mt-3 {
	margin-top: ($spacer * 0.75) !important;
}
.mt-4 {
	margin-top: ($spacer * 1) !important;
}
.mt-5 {
	margin-top: ($spacer * 1.5) !important;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.mb-1 {
	margin-bottom: ($spacer * 0.25) !important;
}
.mb-2 {
	margin-bottom: ($spacer * 0.5) !important;
}
.mb-3 {
	margin-bottom: ($spacer * 0.75) !important;
}
.mb-4 {
	margin-bottom: ($spacer * 1) !important;
}
.mb-5 {
	margin-bottom: ($spacer * 1.5) !important;
}
.mr-0 {
	margin-right: 0 !important;
}
.mr-1 {
	margin-right: ($spacer * 0.25) !important;
}
.mr-2 {
	margin-right: ($spacer * 0.5) !important;
}
.mr-3 {
	margin-right: ($spacer * 0.75) !important;
}
.mr-4 {
	margin-right: ($spacer * 1) !important;
}
.mr-5 {
	margin-right: ($spacer * 1.5) !important;
}
.ml-0 {
	margin-left: 0 !important;
}
.ml-1 {
	margin-left: ($spacer * 0.25) !important;
}
.ml-2 {
	margin-left: ($spacer * 0.5) !important;
}
.ml-3 {
	margin-left: ($spacer * 0.75) !important;
}
.ml-4 {
	margin-left: ($spacer * 1) !important;
}
.ml-5 {
	margin-left: ($spacer * 1.5) !important;
}
.mx-0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
.mx-1 {
	margin-left: ($spacer * 0.25) !important;
	margin-right: ($spacer * 0.25) !important;
}
.mx-2 {
	margin-left: ($spacer * 0.5) !important;
	margin-right: ($spacer * 0.5) !important;
}
.mx-3 {
	margin-left: ($spacer * 0.75) !important;
	margin-right: ($spacer * 0.75) !important;
}
.mx-4 {
	margin-left: ($spacer * 1) !important;
	margin-right: ($spacer * 1) !important;
}
.mx-5 {
	margin-left: ($spacer * 1.5) !important;
	margin-right: ($spacer * 1.5) !important;
}
.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.my-1 {
	margin-top: ($spacer * 0.25) !important;
	margin-bottom: ($spacer * 0.25) !important;
}
.my-2 {
	margin-top: ($spacer * 0.5) !important;
	margin-bottom: ($spacer * 0.5) !important;
}
.my-3 {
	margin-top: ($spacer * 0.75) !important;
	margin-bottom: ($spacer * 0.75) !important;
}
.my-4 {
	margin-top: ($spacer * 1) !important;
	margin-bottom: ($spacer * 1) !important;
}
.my-5 {
	margin-top: ($spacer * 1.5) !important;
	margin-bottom: ($spacer * 1.5) !important;
}
