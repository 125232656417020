@mixin borders($colorString, $color, $width, $positions...) {
	@for $i from 0 to length($positions) {
		@if nth($positions, $i + 1) == "" {
			.border__#{$colorString} {
				border: #{$width}px solid #{$color};
			}
		} @else {
			.border--#{nth($positions, $i + 1)}__#{$colorString} {
				border-#{nth($positions, $i + 1)}: #{$width}px solid #{$color};
			}
		}
	}
}

$borders: (
	"extraextralight": $--background-extraextralight,
	"extralight": $--background-extralight,
	"mediumlight": $--background-mediumlight,
	"light": $--background-light,
	"medium": $--background-medium,
	"white": $--background-white,
	"primary": $--primary-main,
	"error": $--font-error,
);
@each $colorString, $color in $borders {
	@include borders(
		$colorString,
		$color,
		1,
		"",
		"top",
		"bottom",
		"right",
		"left"
	);
}
.border__primary-2 {
	border: 2px solid $--primary-main;
}
// .border__extraextralight {
// 	border: 1px solid $--background-extraextralight;
// }
// .border--top__extraextralight {
// 	border-top: 1px solid $--background-extraextralight;
// }
// .border--bottom__extraextralight {
// 	border-bottom: 1px solid $--background-extraextralight;
// }
// .border--right__extraextralight {
// 	border-right: 1px solid $--background-extraextralight;
// }
// .border--left__extraextralight {
// 	border-left: 1px solid $--background-extraextralight;
// }

// .border__extralight {
// 	border: 1px solid $--background-extralight;
// }
// .border--top__extralight {
// 	border-top: 1px solid $--background-extralight;
// }
// .border--bottom__extralight {
// 	border-bottom: 1px solid $--background-extralight;
// }
// .border--right__extralight {
// 	border-right: 1px solid $--background-extralight;
// }
// .border--left__extralight {
// 	border-left: 1px solid $--background-extralight;
// }

// .border__mediumlight {
// 	border: 1px solid $--background-mediumlight;
// }
// .border--top__mediumlight {
// 	border-top: 1px solid $--background-mediumlight;
// }
// .border--bottom__mediumlight {
// 	border-bottom: 1px solid $--background-mediumlight;
// }
// .border--right__mediumlight {
// 	border-right: 1px solid $--background-mediumlight;
// }
// .border--left__mediumlight {
// 	border-left: 1px solid $--background-mediumlight;
// }

// .border__light {
// 	border: 1px solid $--background-light;
// }
// .border--top__light {
// 	border-top: 1px solid $--background-light;
// }
// .border--bottom__light {
// 	border-bottom: 1px solid $--background-light;
// }
// .border--right__light {
// 	border-right: 1px solid $--background-light;
// }
// .border--left__light {
// 	border-left: 1px solid $--background-light;
// }

// .border__medium {
// 	border: 1px solid $--background-medium;
// }
// .border--top__medium {
// 	border-top: 1px solid $--background-medium;
// }
// .border--bottom__medium {
// 	border-bottom: 1px solid $--background-medium;
// }
// .border--right__medium {
// 	border-right: 1px solid $--background-medium;
// }
// .border--left__medium {
// 	border-left: 1px solid $--background-medium;
// }

// .border__white {
// 	border: 1px solid $--background-white;
// }
// .border--top__white {
// 	border-top: 1px solid $--background-white;
// }
// .border--bottom__white {
// 	border-bottom: 1px solid $--background-white;
// }
// .border--right__white {
// 	border-right: 1px solid $--background-white;
// }
// .border--left__white {
// 	border-left: 1px solid $--background-white;
// }

// .border__primary {
// 	border: 1px solid $--primary-main;
// }
// .border__primary-2 {
// 	border: 2px solid $--primary-main;
// }
// .border--top__primary {
// 	border-top: 1px solid $--primary-main;
// }
// .border--bottom__primary {
// 	border-bottom: 1px solid $--primary-main;
// }
// .border--right__primary {
// 	border-right: 1px solid $--primary-main;
// }
// .border--left__primary {
// 	border-left: 1px solid $--primary-main;
// }

// .border__error {
// 	border: 1px solid $--font-error;
// }
// .border--top__error {
// 	border-top: 1px solid $--font-error;
// }
// .border--bottom__error {
// 	border-bottom: 1px solid $--font-error;
// }
// .border--right__error {
// 	border-right: 1px solid $--font-error;
// }
// .border--left__error {
// 	border-left: 1px solid $--font-error;
// }
