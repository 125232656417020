.gs__container {
    .welcome-and-support-container {
        padding: 0 5% !important;
    }
    .travel-plus-expense-container,
    .getting-started-with-travel-container,
    .getting-started-with-expense-container {
        padding: 0 5% !important;
    }
}

.links-container {
    .link-icon {
        .image__wrapper {
            display: flex;
            align-items: center;
        }
    }
    .link-desc {
        .gs__linkTitle {
            margin-top: 12px;
        }
    }
}

.getting-started-loader-container {
    padding: 5%;
}

.info-modal {
    .modal-dialog {
        .modal-content {
            .modal-body {
                .info-text {
                    font-size: 18px;
                }
            }
        }
    }
}
