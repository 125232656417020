@import "./__init";
@import "./__typography";
@import "./__margin";
@import "./__padding";
@import "./__borders";
@import "./__material-overrides";

body {
	font-size: 14px;
	font-family: $--font-primary;
	font-weight: $normal;
	color: var(--color-text);
}

h1 {
	margin-bottom: $--space-lg;
}

h2 {
	margin-bottom: $--space-md;
}

h3 {
	margin-bottom: $--space-sm;
}

h4 {
	margin-bottom: $--space-xs;
}

p {
	margin-bottom: $--space-sm;
}

input {
	border: 1px solid #e2e8f0;
	padding: 0 15px;
	height: 45px;
	display: flex;
	align-items: center;
	background: $--font-white;
	color: $--font-medium;
	font-weight: $normal;
	font-size: 14px;

	&:focus {
		outline: none;
	}
	&.input-height{
		height: 56px;
	}
}

legend {
	float: none;
}

.box-shadow {
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.box-shadow__none {
	box-shadow: none;
}

.background-medium {
	background: $--background-medium;
}
.background-light {
	background: $--background-light;
}
.background-extralight {
	background: $--background-extralight;
}
.background-extraextralight {
	background: $--background-extraextralight;
}
.background-primary__main {
	background-color: $--primary-main !important;
}
.background-primary__light {
	background-color: $--primary-light !important;
}
.background-white {
	background: $--background-white;
}
.background-transparent {
	background: transparent;
}
.background-white {
	background: $--background-white;
}
.icon-color {
	fill: $--icon-color;
}

.hover-background__white {
	background: $--background-white;
	&:hover {
		background: $--background-white;
	}
}
.d-block {
	display: block !important;
}
.d-flex {
	display: flex !important;
}
.d-inline-flex {
	display: inline-flex !important;
}
.d-none {
	display: none !important;
}
// Flexbox
.align-items-center {
	align-items: center !important;
}
.align-items-start {
	align-items: flex-start !important;
}
.align-items-end {
	align-items: flex-end !important;
}
.justify-content-center {
	justify-content: center;
}
.justify-content-end {
	justify-content: flex-end;
}
.justify-content-start {
	justify-content: flex-start;
}
.justify-content-space-around {
	justify-content: space-around;
}
.justify-content-space-between {
	justify-content: space-between;
}

// Positions
.position-relative {
	position: relative;
}
.position-static {
	position: static;
}
.position-absolute {
	position: absolute;
}

// Width
.fullWidth {
	width: 100%;
}
// Height
.fullHeight {
	height: 100%;
}
//Page init 
.pageSkeleton{
	height: calc(100vh - 114px);
}

//Cursors
.cursor__pointer {
	cursor: pointer;
}
.cursor__notAllowed {
	cursor: not-allowed;
}
.cursor__text {
	cursor: text;
}
.cursor__copy {
	cursor: copy;
}

// Autocomplete

.chip__custom {
	padding: 0px;
	border-radius: 4px;
	margin: 3px 3px 3px 0;
}
// Tooptip arrow

.arrow-up {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;

	&::before {
		bottom: 100%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		border-bottom-color: #ffffff;
		border-radius: 1px;
		border-width: 8px;
		border-bottom-width: 18px;
		box-shadow: 0 21px 29px -7px #000;
		left: 0;
		top: 0;
		transform: translate(100%, -100%);
		margin-left: 0px;
		z-index: 1;
	}
}

// Swal

.swal__sucess {
	.swal-footer {
		display: flex;
		align-items: center;
		justify-content: center;

		button {
			background-color: $--primary-main;
			height: 40px;
			border-radius: 4px;
			font-size: 1rem;

			&:hover {
				background-color: $--primary-main;
			}
		}

		button:hover {
			background-color: $--primary-main;
		}

		button:focus {
			box-shadow: none;
		}
	}
}

.swal-footer {
	display: flex;
	justify-content: center;
}

// toast notification
.toast_notify_success {
	background-color: #579E6E;
}

.toast_notify_error {
	background-color: #ea5c26;
}
.Toastify__close-button {
    margin-top: 10px;
}

@keyframes progress-bar-stripes {
	0% {
		background-position: 40px 0;
	}
	100% {
		background-position: 0 0;
	}
}
@mixin opacity($length) {
	@for $i from 0 to $length {
		.opacity--#{$i} {
			opacity: 0.1 * $i;
		}
	}
}
@include opacity(10);

@import "./__responsive";
