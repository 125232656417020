.footer {
	position: relative;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	background-color: black;
	padding: 0 30px;
	min-height: 50px;
	color: white;
	font-size: 12px;
	line-height: 14px;
	align-items: center;
    width: 100%;
}

.footer__right {
	display: flex;
	justify-content: flex-end;
}

.footer__left {
	display: flex;
}

.footer__left  a,
.footer__right  a {
	color: #ffffff;
	margin-right: 10px;
	padding-right: 10px;
}

.footer__left > div,
.footer__right > div {
	margin-right: 10px;
	padding-right: 10px;
}

.footer__borderRight {
	border-right: 1px solid white;
}

.footer__support__link {
    color: #ec5d25 !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    text-decoration: underline;
}

$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
	.footer {
		font-size: 16px;
		line-height: 18px;
		padding: 10px 0px;
		div {
			flex-direction: column;
			align-items: center;
		}
	}
	.footer__left {
		flex-direction: column;
		align-items: center;
		margin-bottom: 5px;

		a {
			margin-bottom: 5px;
		}

		div {
			margin-right: 0;
			padding-right: 0;
			margin-bottom: 5px;
		}
	}

	.footer__borderRight {
		border: none;
	}

	.footer__right {
		flex-direction: column;
		align-items: center;
		div {
			margin-right: 0;
			padding-right: 0;
			margin-bottom: 5px;
		}
	}
}
