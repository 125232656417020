@media only screen and (max-device-width: 500px) {
	body{
		font-size: 12px;
	}
	
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	/* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	/* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
	body{
		font-size: 14px;
	}
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
	body{
		font-size: 16px;
	}

}
