@import "../../theming/__init.scss";
@import "../../theming/__materialOveridesNew.scss";

.sidebar__container {
  flex: 0.17;
  max-width: 218px;
  height: calc(100vh - 114px);
  background: #ffffff;
  padding: 0;
  position: sticky;
  top: 64px;
  overflow-y: auto;

  .submenu__level {
    .MuiListItemText-root {
      .MuiTypography-body1 {
        font-weight: 500;
        color: $gray600;
        font-size: $textmd;
        padding-left: 5px;
      }
    }
  }

  .sidebar__wrapper {
    overflow-y: auto;
    overflow-x: hidden;

    .submenu_wrapper {
      .MuiCollapse-wrapper {
        .MuiCollapse-wrapperInner {
          border-left: 1px solid #e5e7eb;
          margin: 10px 0px 10px 2rem;
        }
      }
    }
  }
  .sidebar__navlinks {
    background: $white;
    height: $spacer12;
    color: $gray600;
    text-decoration: none;
    .sidebar__navlinks-title span {
      font-size: $textmd;
      font-weight: 500;
    }
    .subsidebar__navlinks-title span {
      font-size: $textmd;
      font-weight: $textNormal;
    }
    .MuiListItem-button {
      border-left: 5px solid $white;
      &:hover {
        background: transparent;
      }
    }
    .Mui-selected {
      background: $white;
      border-left: 5px solid $themeMain;
      margin-left: 0;
      .sidebar__navlinks-title span {
        color: $themeMain;
        font-weight: $textSemibold;
      }
    }
  }
}
