@import "./__init.scss";

.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6,
.MuiTypography-body1,
.MuiTypography-body2,
.MuiChip-root,
.MuiTypography-caption,
.MuiButton-root {
	font-family: $fontSecondary;
}
.MuiButton-containedPrimary {
	background-color: $orange600;
	color: $white;
	&:hover,
	&:focus {
		background-color: $orange600;
		color: $white;
		box-shadow: $buttonBoxShadow;
	}
	&.Mui-disabled {
		background: $orange600;
		color: $white;
	}
}
.MuiIconButton-root{
	box-shadow: none;
}
.MuiInputBase-root {
	background: $white;
	box-shadow: $boxShadow1;

	.MuiOutlinedInput-notchedOutline {
		border-color: $gray300;
	}
	&.Mui-focused .MuiOutlinedInput-notchedOutline {
		box-shadow: $boxShadow1;
		border-width: 0px;
	}
	&:hover .MuiOutlinedInput-notchedOutline{
		box-shadow: $boxShadow1;
		border-color: transparent;
	}
}
.MuiChip-root {
	background: $gray200;
	color: $black;
	font-size: $textlg;
	font-weight: $textSemibold;
	border-radius: 4px;
	min-width: $spacer24;
	height: auto;
	padding: $spacer1;
	justify-content: space-between;
	margin-right: $spacer1;
	margin-bottom: $spacer1;
	.MuiChip-label {
		padding: 0 $spacer4 0 0;
	}
}
